import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLanguage } from '../contexts/LanguageContext';
import { useUser } from "../contexts/UserContext";
import Request from '../types/Request';
import FetchError from '../types/Error';
import Loader from './Loader';
import NotFound from "./NotFound";
import apiRequest from "../utils/api";
import usePullToRefresh from '../utils/pullToRefresh'
import { GoogleMap, OwnRequestMap } from './GoogleMap';
import { RequestModalProps } from "../types/Props";
import { viewRequest } from '../utils/viewUtils';
import { useUserLocation } from "../utils/locationUtils";
import { isValidInput, isValidLength } from "../utils/validateInput";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faToolbox, 
  faBottleWater,
  faCar,
  faHandshakeAngle,
  faUser, 
  faPhone, 
  faUpload, 
  faRotateRight,
  faEye,
  faPenToSquare,
  faFloppyDisk, 
  faXmark,
  faLocationDot,
  faCheck,
  faTrash,
  faClipboardCheck,
  faBan,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { formatDistanceStrict } from 'date-fns';
import { enUS, fi, sv } from 'date-fns/locale';

const RequestModal: React.FC<RequestModalProps> = ({ onClose, isUserRequest, request_id, onUpdate }) => {
  const { translations, language } = useLanguage();
  const { user } = useUser();
  const [request, setRequest] = useState<Request>();
  const [originalRequest, setOriginalRequest] = useState<Request>();
  const checkIsUserRequest = Boolean(isUserRequest); // isUserRequest is 0 or 1, convert to boolean
  const [loading, setLoading] = useState<boolean>(true);
  const [descSuccess, setDescSuccess] = useState<string | null>(null);
  const [locationSuccess, setLocationSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [descError, setDescError] = useState<string | null>(null);
  const [locError, setLocError] = useState<string | null>(null);
  const [limitError, setLimitError] = useState<string | null>(null);
  const [isEditMode, setIsEditMode] = useState(false); 
  const [isAccepted, setIsAccepted] = useState(true);
  const { userLatitude, userLongitude } = useUserLocation();
  const [showReadyConfirmation, setShowReadyConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [alreadyResponded, setAlreadyResponded] = useState(false);
  const [alreadyHasResponder, setAlreadyHasResponder] = useState(false);
  const maxLength = 500;
  const descriptionPattern = /^[a-zA-ZäöÄÖ0-9\s.,!?:-]*$/;

  const statusColor = {
    'new': 'status-red',
    'in progress': 'status-yellow',
    'completed': 'status-green'
  }

  const categoryIcons = {
    bicycle_maintenance: faToolbox,
    food_and_hydration: faBottleWater,
    transportation_need: faCar,
    other: faHandshakeAngle
  };

  const fetchRequestData = useCallback(async () => {
    try {
      const response = await apiRequest(`/api/requests/${request_id}`);
      if (!response.ok) {
        throw new Error(translations.errors.failedtofetchrequest);
      }
      const data = await response.json();
      setRequest(data);
      setOriginalRequest(data);
      setIsAccepted(data.status !== 'new');
    } catch (error) {
      const fetchError = error as FetchError;
      setError(fetchError.message);
    } finally {
      setLoading(false);
    }
  }, [request_id, translations.errors.failedtofetchrequest]);

  useEffect(() => {
    fetchRequestData();
  }, [fetchRequestData]);

  // Add view to request if user is not the requester
  useEffect(() => {
    if (!isUserRequest && user) {
      viewRequest(request_id);
    }
  }, [request_id, isUserRequest, user]);

  // Pull to refresh
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { 
    isRefreshing, 
    spinnerSize, 
    handleTouchStart, 
    handleTouchMove, 
    handleTouchEnd 
  } = usePullToRefresh(fetchRequestData, 100, scrollContainerRef);

  if (loading || !user ) return <Loader />;

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    setDescSuccess(null);
  };

  const updateRequest = async (url: string, body?: object) => {
    try {
      const options: RequestInit = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      };
  
      if (body) {
        options.body = JSON.stringify(body);
      }
  
      const response = await apiRequest(url, options);
      const data = await response.json();
      if (!response.ok) {
        if (data.message === 'You have already responded to this request.') {
          setAlreadyResponded(true);
        } else if (data.message === 'This request already has a responder.') {
          setAlreadyHasResponder(true);
        } else if (data.message === 'You have reached the limit of 2 active responses.') {
          setLimitError(translations.errors.responselimitreached);
        } else {
          setError(data.message);
        }
      }
      return response;
    } catch (error) {
      return { ok: false };
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setDescError('');
    if (!isValidLength(request?.description ?? '', 1, maxLength)) {
      setDescError(translations.errors.invaliddesclength);
      return;
    }

    if (!isValidInput(request?.description ?? '', descriptionPattern)) {
      setDescError(translations.errors.invalidcharacters);
      return;
    }

    await updateRequest(`/api/requests/${request_id}`, { description: request?.description });
    fetchRequestData();
    onUpdate();
    setDescSuccess(translations.successes.descupdated);
    setIsEditMode(false);
  }

  const handleUpdateLocation = async (e: React.FormEvent) => {
    if (userLatitude && userLongitude) {
      await updateRequest(`/api/requests/${request_id}/location`, { latitude: userLatitude, longitude: userLongitude });
      setLocationSuccess(translations.successes.locationupdated);
      fetchRequestData();
      onUpdate();
    } else {
      setLocError(translations.errors.locationfailed);
    }
  }

  const handleAccept = async () => {
    setIsAccepted(true);
    const response = await updateRequest(`/api/requests/${request_id}/addresponder`);
    if (response.ok) {
      setShowAcceptConfirmation(false);
      fetchRequestData();
      onUpdate();
    } else {
      setShowAcceptConfirmation(false);
    }
  }

  const removeResponder = async () => {
    const removedBy = isUserRequest ? 'owner' : 'responder';
    await updateRequest(`/api/requests/${request_id}/removeresponder`, { removedBy });
    setShowRemoveConfirmation(false);
    fetchRequestData();
    onUpdate();
  }

  const handleReady = async () => {
    await updateRequest(`/api/requests/${request_id}/setready`);
    setShowReadyConfirmation(false);
    fetchRequestData();
    onUpdate();
  }

  const handleCancel = async () => {
    setRequest(originalRequest);
    setIsEditMode(false);
    setDescError('');
  }

  const handleDelete = async () => {
    try {
      const response = await apiRequest(`/api/requests/${request_id}`, {
        method: 'DELETE'
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message);
      } else {
        window.location.reload();
      }
    }
    catch (error) {
      setError(translations.errors.genericerror);
    }
  };

  return (
    <div className="modal-container">
      {request ? (
        <div 
          className="modal-content-full"
          ref={scrollContainerRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {isRefreshing && (
            <div 
              className="loading-indicator" 
              style={{ height: `${spinnerSize}px` }}
            >
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
          <div className="top-bar-modal">
            <h2>
              {translations[request.category]}
              <FontAwesomeIcon icon={categoryIcons[request.category]} className="category-icon" />
            </h2>
            <button className='modal-close' onClick={onClose}>X</button>
          </div>
          <div className="modal-content">
            {error && <div className="error">{error}</div>}
            <p className="intertitle">{translations.basicinfo}</p>
            <div className="card">
              <div className="request-user-and-status">
                <div>
                  <div className="spacer1">
                    <div><FontAwesomeIcon icon={faUser} className="listIcon"/>{request.user_name}</div>
                  </div>
                  { request.status !== 'completed' && (
                    <div>
                      <FontAwesomeIcon icon={faPhone} className="listIcon"/>
                      <a href={`tel:${request.phone}`} className="phonelink">{request.phone}</a>
                    </div>
                  )}
                </div>
                <div>
                  <small>
                    {translations.status}: {translations[request.status]}
                    <span className={`status-circle modal-circle ${statusColor[request.status] || ''}`}></span>
                  </small>
                </div>
              </div>
              <div>
                <small>
                  <div className="spacer2">
                    <FontAwesomeIcon icon={faRotateRight} className='listIcon'/>
                    <span className='text-spacer'>{translations.lastupdated}</span>
                    {formatDistanceStrict(new Date(request.updated_at), new Date(), { 
                      addSuffix: true, 
                      locale: language === 'finnish' ? fi : language === 'swedish' ? sv : enUS 
                    })}
                  </div>
                  <div className="spacer2">
                    <FontAwesomeIcon icon={faUpload} className="listIcon"/>
                    <span className='text-spacer'>{translations.created}</span>
                    {formatDistanceStrict(new Date(request.created_at), new Date(), { 
                      addSuffix: true, 
                      locale: language === 'finnish' ? fi : language === 'swedish' ? sv : enUS 
                    })}
                  </div>
                  { checkIsUserRequest &&
                    <div>
                      <FontAwesomeIcon icon={faEye} className='listIcon'/>
                      <span>{translations.views}: {request.views}</span>
                    </div>
                  }
                </small>
              </div>
            </div>
            <p className="intertitle">{translations.location}</p>
            { request.latitude && request.longitude ? (
              checkIsUserRequest ? (
                <div className="card">
                  <OwnRequestMap 
                    latitude={request.latitude} 
                    longitude={request.longitude} 
                    isApproximate={request.isApproximate}
                  />
                </div>
              ) : (
                <div className="card">
                  <GoogleMap
                    latitude={request.latitude} 
                    longitude={request.longitude} 
                    userLatitude={userLatitude} 
                    userLongitude={userLongitude} 
                    isApproximate={request.isApproximate}
                  />
                </div>
              )
            ) : (
              <div className="card">{translations.locationnotshared}</div>
            )}
            { checkIsUserRequest ? (
              <div>
                { request.status !== "completed" && (request.latitude && request.longitude) &&
                  <button className="big-button update-location" onClick={handleUpdateLocation}>
                    <FontAwesomeIcon icon={faLocationDot}/> {translations.updatelocation}
                  </button>
                }
                { locationSuccess && <div className="success">{locationSuccess}</div> }
                { locError && <div className="error">{locError}</div> }
                { request.responder_user_name && (
                  <div>
                    <p className="intertitle">{translations.requestresponder}</p>
                    <div className="card responder-card">
                      <div className="responder-info">
                        <div>
                          <FontAwesomeIcon icon={faUser} className="listIcon"/>{request.responder_user_name}
                        </div>
                        <div>
                          <FontAwesomeIcon icon={faPhone} className="listIcon"/>
                          <a href={`tel:${request.responder_phone}`} className="phonelink">{request.responder_phone}</a>
                        </div>
                      </div>
                      <button className="big-button removeresponder" onClick={() => setShowRemoveConfirmation(true)}>
                      <FontAwesomeIcon icon={faBan}/> {translations.remove}
                      </button>
                    </div>
                  </div>
                )}
                { request.status !== 'completed' ? (
                <div>
                  <p className="intertitle">{translations.description}</p>
                  <div className="card">
                    <textarea
                      name="description" 
                      rows={4} 
                      cols={40} 
                      value={request.description}
                      disabled={!isEditMode}
                      maxLength={maxLength}
                      onChange={(e) => setRequest({ ...request, description: e.target.value })}
                    />
                    <div className="char-counter">
                      {request.description.length}/{maxLength} {translations.characters}
                    </div>
                    { descSuccess && <div className="success">{descSuccess}</div> }
                    { descError && <div className="error">{descError}</div> }
                    <button 
                      type="button" 
                      className={`big-button edit ${!isEditMode ? 'active-edit' : 'inactive-edit'}`}
                      onClick={toggleEditMode}
                      disabled={isEditMode}
                    >
                      <FontAwesomeIcon icon={faPenToSquare}/> {translations.editdesc}
                    </button>
                    <button 
                      type="button"
                      className={`big-button desc ${isEditMode ? 'active-edit' : 'inactive-edit'}`}
                      onClick={handleSubmit}
                      disabled={!isEditMode}
                    >
                      <FontAwesomeIcon icon={faFloppyDisk}/> {translations.save}
                    </button>
                    <button
                      type="button"
                      className={`big-button cancel desc ${isEditMode ? 'active-edit' : 'inactive-edit'}`}
                      onClick={handleCancel}
                      disabled={!isEditMode}
                    >
                      <FontAwesomeIcon icon={faXmark}/> {translations.cancel}
                    </button>
                  </div>
                  <button className="big-button ready" onClick={() => setShowReadyConfirmation(true)}>
                    <FontAwesomeIcon icon={faCheck}/> {translations.markasready}
                  </button>
                  { showReadyConfirmation && (
                    <div className="confirmation-overlay">
                      <div className="card confirm-card">
                        <p>{translations.confirmready}</p>
                        <div className="confirmation-buttons">
                          <button className="big-button confirmation-cancel" onClick={() => setShowReadyConfirmation(false)}>
                            <FontAwesomeIcon icon={faXmark}/> {translations.cancel}
                          </button>
                          <button className="big-button confirmation-confirm mark-as-ready" onClick={handleReady}>
                            <FontAwesomeIcon icon={faCheck}/> {translations.markasready}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <button className="big-button delete" onClick={() => setShowDeleteConfirmation(true)}>
                    <FontAwesomeIcon icon={faTrash}/> {translations.delete}
                  </button>
                  { showDeleteConfirmation && (
                    <div className="confirmation-overlay">
                      <div className="card confirm-card">
                        <p>{translations.confirmdelete}</p>
                        <div className="confirmation-buttons">
                          <button className="big-button confirmation-cancel grey" onClick={() => setShowDeleteConfirmation(false)}>
                            <FontAwesomeIcon icon={faXmark}/> {translations.cancel}
                          </button>
                          <button className="big-button confirmation-confirm warning" onClick={handleDelete}>
                            <FontAwesomeIcon icon={faTrash}/> {translations.delete}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                ): (
                  <div>
                    <p className="intertitle">{translations.description}</p>
                    <div className="card">{request.description}</div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p className="intertitle">{translations.description}</p>
                <div className="card">{request.description}</div>
                {request.responder_user_name ? (
                  request.responder_user_name === user?.user_name ? (
                    <button
                      type="submit" 
                      className="big-button removeresponder-self"
                      onClick={() => setShowRemoveConfirmation(true)}
                    >
                      <FontAwesomeIcon icon={faBan}/> {translations.release}
                    </button>
                  ) : (
                    <button
                      type="submit" 
                      className="big-button workingonit"
                      disabled
                    >
                      {translations.workingonit}
                    </button>
                  )
                ) : (
                  request.status === 'new' && (
                    <div>
                      {alreadyResponded && <div className="error">{translations.errors.alreadyresponded}</div>}
                      {alreadyHasResponder && <div className="error">{translations.errors.alreadyhasresponder}</div>}
                      {limitError && <div className="error">{limitError}</div>}
                      <button
                        type="submit" 
                        className="big-button takeover"
                        onClick={() => setShowAcceptConfirmation(true)}
                        disabled={isAccepted}
                      >
                        <FontAwesomeIcon icon={faClipboardCheck}/> {translations.takeover}
                      </button>
                      { showAcceptConfirmation && (
                        <div className="confirmation-overlay">
                          <div className="card confirm-card">
                            <p>{translations.confirmtakeover}</p>
                            <div className="confirmation-buttons">
                              <button className="big-button confirmation-cancel" onClick={() => setShowAcceptConfirmation(false)}>
                                <FontAwesomeIcon icon={faXmark}/> {translations.cancel}
                              </button>
                              <button className="big-button confirmation-confirm takeover" onClick={handleAccept}>
                                <FontAwesomeIcon icon={faCheck}/> {translations.takeover}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                )}
            </div>
            )}
          </div>
        </div>
      ) : (
        <div className="modal-content-full">
          <div className="top-bar-modal">
            <h2>-</h2>
            <button className='modal-close' onClick={onClose}>X</button>
          </div>
          <div className="modal-content">
            <NotFound source="modal"/>
          </div>
        </div>
      )}
      { showRemoveConfirmation && (
        <div className="confirmation-overlay">
          <div className="card confirm-card">
            {isUserRequest ? ( <p>{translations.confirmremove}</p> ):( <p>{translations.confirmremoveself}</p>)}
            <div className="confirmation-buttons">
              <button className="big-button confirmation-cancel grey" onClick={() => setShowRemoveConfirmation(false)}>
               <FontAwesomeIcon icon={faXmark}/> {translations.cancel}
              </button>
              <button className="big-button confirmation-confirm warning" onClick={removeResponder}>
               <FontAwesomeIcon icon={faBan}/> {isUserRequest ? translations.remove : translations.release}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestModal;