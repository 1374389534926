import React, { useEffect, useState } from "react";
import { useLanguage } from '../contexts/LanguageContext';
import { useUser } from "../contexts/UserContext";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoginProps } from "../types/Props";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login: React.FC<LoginProps> = ({ setIsLoggedIn }) => {
  const { translations } = useLanguage();
  const { setUser } = useUser();
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const messageParam = params.get('message');
    if (messageParam === 'password-updated') {
      setMessage(translations.passwordchanged);
    }
  }, [location, translations.passwordchanged]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIdentifier('');
    setPassword('');
    setError('');

    try {
      const response = await fetch('/api/user/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ identifier, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 400) {
          if (data.message === 'Invalid username or password') {
            setError(translations.errors.invalidUnameorPass);
          } else {
            setError(translations.errors.badrequest);
          }
        } else {
          setError(data.message);
        }
      } else {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        setUser(data.user);
        setIsLoggedIn(true);
        navigate('/');
      }
    } catch (error) {
      setError(translations.errors.genericerror);
    }
  };

  return (
    <div className="auth-container">
      <div className="card auth-card">
        <div className="card-header">
          <img src='/mainlogo.png' className='card-header-logo' alt='Login Page Logo'></img>
        </div>
        <form onSubmit={handleSubmit}>  
          <h2>{translations.login}</h2>
          {message && <div className="password-change-success">{message}</div>}
          <label>
            {translations.userid}
            <input
              type="text"
              className="auth-input"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              placeholder={translations.usernameorphonenumber}
              required
            />
          </label>
          <label>
            {translations.password}:
            <div className="input-container-password">
              <input
                type={showPassword ? 'text' : 'password'}
                className="auth-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={translations.password}
                required
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="password-icon"
                onClick={togglePasswordVisibility}
              />
            </div>
          </label>
          {error && <p className="error auth-error">{error}</p>}
          <button className="big-button login-button" type="submit">{translations.login}</button>
          <Link to="/register">
            <button className="big-button newuser-button">
              {translations.newuser} {translations.registerhere}
            </button>
          </Link>
          <span className="pswreg"><Link to="/resetpassword">{translations.forgotpassword}</Link></span>
        </form>
      </div>
    </div>
  );
}

export default Login;
