import React, { useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { APIProvider, Map, AdvancedMarker, Pin, useMap } from '@vis.gl/react-google-maps';
import { GoogleMapProps } from '../types/Props';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const mapsId = process.env.REACT_APP_GOOGLE_MAPS_ID;

const MapWithMarkers: React.FC<GoogleMapProps> = ({ latitude, longitude, userLatitude, userLongitude, isApproximate }) => {
  const { translations } = useLanguage();
  const map = useMap();

  useEffect(() => {
    if (map && userLatitude != null && userLongitude != null) {
      const bounds = new window.google.maps.LatLngBounds();
      const requestLocation = new window.google.maps.LatLng(latitude, longitude);
      const userLocation = new window.google.maps.LatLng(userLatitude, userLongitude);

      bounds.extend(requestLocation);
      bounds.extend(userLocation);

      map.fitBounds(bounds);
      
    }
  }, [map, latitude, longitude, userLatitude, userLongitude]);

  // Draw a circle around the request location if the location data is approximate
  useEffect(() => {
    if (map && isApproximate) {
      const roundedLatitude = parseFloat(latitude.toFixed(2));
      const roundedLongitude = parseFloat(longitude.toFixed(2));

      const circle = new window.google.maps.Circle({
        center: { lat: roundedLatitude, lng: roundedLongitude },
        radius: 2000,
        fillColor: "#ff5454",
        fillOpacity: 0.3,
        strokeColor: "#ff5454",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        map: map,
      });

      return () => {
        circle.setMap(null);
      };
    }
  }, [map, latitude, longitude, isApproximate]);

  if (!mapsId) {
    return <div>{translations.errors.maperror}</div>;
  }

  return (
    <Map
      defaultZoom={10}
      defaultCenter={{ lat: latitude, lng: longitude }}
      mapId={mapsId}
      className='map'
      streetViewControl={false}
    >
      {/* Draw marker if the location is not approximate */}
      {!isApproximate && (
        <AdvancedMarker position={{ lat: latitude, lng: longitude }}>
          <Pin background={"#ff5454"} glyphColor={"#000"} borderColor={"#000"} />
        </AdvancedMarker>
      )}
      {userLatitude != null && userLongitude != null && (
        <AdvancedMarker position={{ lat: userLatitude, lng: userLongitude }}>
          <Pin background={'#00aeff'} glyphColor={'#000'} borderColor={'#000'} />
        </AdvancedMarker>
      )}
    </Map>
  );
}

const GoogleMap: React.FC<GoogleMapProps> = ({ latitude, longitude, userLatitude, userLongitude, isApproximate }) => {
  const { translations } = useLanguage();

  if (!apiKey) {
    return <div>{translations.errors.maperror}</div>;
  }

  return (
    <div>
      <APIProvider apiKey={apiKey} libraries={['geometry']}>
        <div style={{ width: '100%', height: '300px' }}>
          <MapWithMarkers
            latitude={latitude}
            longitude={longitude}
            userLatitude={userLatitude}
            userLongitude={userLongitude}
            isApproximate={isApproximate}
          />
        </div>
      </APIProvider>
      <div className="markercontainer">
        <div className="marker-item">
          <span className="marker-color" style={{ backgroundColor: '#ff5454' }}></span>
          <span>{translations.requestlocation}</span>
        </div>
        {userLatitude != null && userLongitude != null && (
          <div className="marker-item">
            <span className="marker-color" style={{ backgroundColor: '#00aeff' }}></span>
            <span>{translations.ownlocation}</span>
          </div>
        )}
      </div>
    </div>
  );
}

const OwnRequestMap: React.FC<GoogleMapProps> = ({ latitude, longitude, isApproximate }) => {
  const { translations } = useLanguage();

  if (!apiKey || !mapsId) {
    return <div>{translations.errors.maperror}</div>;
  }

  return (
    <div>
      <APIProvider apiKey={apiKey} libraries={['geometry']}>
        <div style={{ width: '100%', height: '300px' }}>
          <OwnRequestMapContent latitude={latitude} longitude={longitude} isApproximate={isApproximate} />
        </div>
      </APIProvider>
    </div>
  );
}

const OwnRequestMapContent: React.FC<GoogleMapProps> = ({ latitude, longitude, isApproximate }) => {
  const map = useMap();

  useEffect(() => {
    if (map && isApproximate) {
      const roundedLatitude = parseFloat(latitude.toFixed(2));
      const roundedLongitude = parseFloat(longitude.toFixed(2));

      const circle = new window.google.maps.Circle({
        center: { lat: roundedLatitude, lng: roundedLongitude },
        radius: 2000,
        fillColor: "#ff5454",
        fillOpacity: 0.3,
        strokeColor: "#ff5454",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        map: map,
      });

      return () => {
        circle.setMap(null);
      };
    }
  }, [map, latitude, longitude, isApproximate]);

  return (
    <Map
      defaultZoom={12}
      defaultCenter={{ lat: latitude, lng: longitude }}
      mapId={mapsId}
      className='map'
      streetViewControl={false}
    >
      {/* Draw marker if the location is not approximate */}
      {!isApproximate && (
        <AdvancedMarker position={{ lat: latitude, lng: longitude }}>
          <Pin background={"#ff5454"} glyphColor={"#000"} borderColor={"#000"} />
        </AdvancedMarker>
      )}
    </Map>
  );
};

export { GoogleMap, OwnRequestMap };